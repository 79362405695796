@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,700,900'); 

* {
    box-sizing: border-box;
}

/*####################### general #####################*/
html {
    scroll-behavior: smooth;
    font-family: 'Raleway', sans-serif;
}

body {
    background-color: rgb(60, 66, 75);
    color: white;
    text-align: center;
    margin: 0;
    //display: flex;
    //min-height: 100vh;
    //flex-direction: column;
}
main {
    width: 100%;
    max-width: 960px;
    margin: auto;
    padding-bottom: 7rem;
    display: block;
    flex: 1 0 auto;
}

p {
    font-size: 2rem;
}

header {
    text-align: left;
}

h1 {
    max-width: 100%;
    margin: auto;
    padding-top: 15rem;
    margin-bottom: 3rem;    
    font-size: 9rem;
    font-weight: 400;  
}

li {
    text-decoration: none;
}

