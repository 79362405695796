@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,700,900);
* {
    box-sizing: border-box;
}

/*####################### general #####################*/
html {
    scroll-behavior: smooth;
    font-family: 'Raleway', sans-serif;
}

body {
    background-color: rgb(60, 66, 75);
    color: white;
    text-align: center;
    margin: 0;
    //display: flex;
    //min-height: 100vh;
    //flex-direction: column;
}
main {
    width: 100%;
    max-width: 960px;
    margin: auto;
    padding-bottom: 7rem;
    display: block;
    flex: 1 0 auto;
}

p {
    font-size: 2rem;
}

header {
    text-align: left;
}

h1 {
    max-width: 100%;
    margin: auto;
    padding-top: 15rem;
    margin-bottom: 3rem;    
    font-size: 9rem;
    font-weight: 400;  
}

li {
    text-decoration: none;
}


ul {
    margin: auto;
    padding: em;
}
li {
    list-style: none;
    display: inline-block;
    padding: 0em 1rem;
}
a {
    text-decoration: none; 
    color: white;
    font-size: 2rem;
}
a:hover {
    color: gray;
}
.Home_aboutMe__jdI2o {
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    margin-top: 10rem;
    padding: 1.5rem;
    font-size: 2rem; 
    color: rgb(158, 166, 173);
    border-top: dotted;
    border-color: rgba(240, 248, 255, 0.144);
}
.PortfolioItem_portItem__Z9TQV {
    position: relative;
    margin-bottom: 10%;
}
.PortfolioItem_portItem__Z9TQV img {
    width: 90%;
    border-bottom-right-radius: 80%;
    border-top-left-radius: 15%;
    border-top-right-radius: 15%;
    border-bottom-left-radius: 15%;
    filter: blur(0  px);
    transition: border-radius .5s, filter .5s;
}
.PortfolioItem_portItem__Z9TQV:hover img{
    border-radius: 5%;
    filter: blur(0px);
}
.PortfolioItem_portDesc__3AySy {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    bottom: 1.5em;
    color: rgba(255, 255, 255, 0);
    -webkit-backdrop-filter: blur(0px);
    backdrop-filter: blur(0px);
    padding-bottom: .5em;
    padding-top: .5em;
    margin: 0;
    transition: color .2s, background-color .2s, -webkit-backdrop-filter .1s ease .1s;
    transition: color .2s, backdrop-filter .1s ease .1s, background-color .2s;
    transition: color .2s, backdrop-filter .1s ease .1s, background-color .2s, -webkit-backdrop-filter .1s ease .1s;
}
.PortfolioItem_portItem__Z9TQV:hover .PortfolioItem_portDesc__3AySy {
    color: white;
    background-color: rgba(255, 255, 255, 0.027);
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px) ;
}
.PortfolioItem_portDesc__3AySy p {
    font-size: 200%;
    margin: .1em 0.5em;
}
.PortfolioList_category__1soL_ {
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 2rem;
    margin-left: 1rem;
    padding-right: 7rem;
    padding-left: 0.4rem;
    padding-bottom: 0rem; 
    padding-top: 0;
    font-size: 4rem;
    font-weight: 100;
    border-bottom: dotted;
    border-color: rgb(146, 145, 145);
}
.Projects_portfolio__i865P {
    padding-top: 10rem;
    width: 100%;
    margin: auto;
}
.TimelineItemLeft_item__kHNma {
    left: 0;
    max-width: 100%;
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;   
    
  
}
.TimelineItemLeft_item__kHNma::before { /* arrows  */
    content: '';
    
    height: 0;
    position: absolute;
    top: 13px;
    width: 0;
    z-index: 1;
    border: medium solid white;
    right: 50px;    
    border-width: 10px 0 10px 17px;
    border-color: transparent transparent transparent white;
}
.TimelineItemLeft_item__kHNma::after { /* circles */
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-color: rgb(60, 66, 75);
    border: 4px solid white;
    top: 5px;
    border-radius: 50%;
    z-index: 1;
}

.TimelineItemLeft_content__2VoVo {
    position: relative; 
    right: 35px;
    padding: 25px 30px; 
    border: 3px solid white;
    border-radius: 25px;
    border-top-right-radius: 0;
    
}
.TimelineItemLeft_content__2VoVo h2 {
    margin: 0;
    font-size: 2rem;
}
.TimelineItemLeft_content__2VoVo p {
    margin: 0;
    margin-top: 1rem;
    font-size: 1.5rem;
}
.TimelineItemRight_item__2bNbo {
    left: 50%;
    max-width: 100%;
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
}
.TimelineItemRight_item__2bNbo::before { /* arrows  */
    content: '';
    height: 0;
    position: absolute;
    top: 13px;
    width: 0;
    z-index: 1;
    border: medium solid white;
    left: 50px;
    border-width: 10px 17px 10px 0;
    border-color: transparent white transparent transparent;
}
.TimelineItemRight_item__2bNbo::after { /* circles */
    content: '';
    left: -16px;
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-color: rgb(60, 66, 75);
    border: 4px solid white;
    top: 5px;
    border-radius: 50%;
    z-index: 1;
}

.TimelineItemRight_content__1-YKB {
    position: relative;
    left: 35px; 
    padding: 25px 30px;
    border: 3px solid white;
    border-radius: 25px;
    border-top-left-radius: 0;
}
.TimelineItemRight_content__1-YKB h2 {
    margin: 0;
    font-size: 2rem;
}
.TimelineItemRight_content__1-YKB p {
    margin: 0;
    margin-top: 1rem;
    font-size: 1.5rem;
}
.Timeline_timeline__3si6q {
    position: relative;
    width: 100%;
    margin: 0 auto;
    margin-top: 10rem;
    padding-top: 50px;
}
.Timeline_timeline__3si6q ul {
    width: 100%;
}
.Timeline_timeline__3si6q::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: white;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
}
.Layout_flex__2ezjr {
    background-color: rgb(60, 66, 75);
    color: white;
    text-align: center;
    margin: 0;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.Layout_homeButton__pANxo {
    position: fixed;
    z-index: 9999;
    margin-top: .8rem;
    margin-left: .8rem;
    padding: .6rem ;
    border-radius: 70%;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
}
.Layout_homeButton__pANxo li {
    padding: 0;
}
.Layout_homeButton__pANxo a {
    font-size: 2.5rem;
}
footer {
    bottom: 0;
    margin: 0;

    background-color: rgb(50, 55, 62.5);
    border-width: 0;
}
footer li {
    padding: 0 4rem;
}
footer a {
    padding: 0;
}
footer p {
    padding: 1rem;
    margin: 0;
    margin-top: 0rem;
    font-size: .8rem;
    color: white;
    transition: color .25s;
}
footer img {
    width: 30px;
    margin-top: .65rem;
}
footer li:hover p {
    color: rgb(158, 158, 158);
}
