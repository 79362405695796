ul {
    margin: auto;
    padding: em;
}
li {
    list-style: none;
    display: inline-block;
    padding: 0em 1rem;
}
a {
    text-decoration: none; 
    color: white;
    font-size: 2rem;
}
a:hover {
    color: gray;
}