.flex {
    background-color: rgb(60, 66, 75);
    color: white;
    text-align: center;
    margin: 0;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.homeButton {
    position: fixed;
    z-index: 9999;
    margin-top: .8rem;
    margin-left: .8rem;
    padding: .6rem ;
    border-radius: 70%;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
}
.homeButton li {
    padding: 0;
}
.homeButton a {
    font-size: 2.5rem;
}
footer {
    bottom: 0;
    margin: 0;

    background-color: rgb(50, 55, 62.5);
    border-width: 0;
}
footer li {
    padding: 0 4rem;
}
footer a {
    padding: 0;
}
footer p {
    padding: 1rem;
    margin: 0;
    margin-top: 0rem;
    font-size: .8rem;
    color: white;
    transition: color .25s;
}
footer img {
    width: 30px;
    margin-top: .65rem;
}
footer li:hover p {
    color: rgb(158, 158, 158);
}