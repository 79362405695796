.item {
    left: 0;
    max-width: 100%;
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;   
    
  
}
.item::before { /* arrows  */
    content: '';
    
    height: 0;
    position: absolute;
    top: 13px;
    width: 0;
    z-index: 1;
    border: medium solid white;
    right: 50px;    
    border-width: 10px 0 10px 17px;
    border-color: transparent transparent transparent white;
}
.item::after { /* circles */
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-color: rgb(60, 66, 75);
    border: 4px solid white;
    top: 5px;
    border-radius: 50%;
    z-index: 1;
}

.content {
    position: relative; 
    right: 35px;
    padding: 25px 30px; 
    border: 3px solid white;
    border-radius: 25px;
    border-top-right-radius: 0;
    
}
.content h2 {
    margin: 0;
    font-size: 2rem;
}
.content p {
    margin: 0;
    margin-top: 1rem;
    font-size: 1.5rem;
}