.portItem {
    position: relative;
    margin-bottom: 10%;
}
.portItem img {
    width: 90%;
    border-bottom-right-radius: 80%;
    border-top-left-radius: 15%;
    border-top-right-radius: 15%;
    border-bottom-left-radius: 15%;
    filter: blur(0  px);
    transition: border-radius .5s, filter .5s;
}
.portItem:hover img{
    border-radius: 5%;
    filter: blur(0px);
}
.portDesc {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    bottom: 1.5em;
    color: rgba(255, 255, 255, 0);
    -webkit-backdrop-filter: blur(0px);
    backdrop-filter: blur(0px);
    padding-bottom: .5em;
    padding-top: .5em;
    margin: 0;
    transition: color .2s, backdrop-filter .1s ease .1s, background-color .2s;
}
.portItem:hover .portDesc {
    color: white;
    background-color: rgba(255, 255, 255, 0.027);
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px) ;
}
.portDesc p {
    font-size: 200%;
    margin: .1em 0.5em;
}