.timeline {
    position: relative;
    width: 100%;
    margin: 0 auto;
    margin-top: 10rem;
    padding-top: 50px;
}
.timeline ul {
    width: 100%;
}
.timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: white;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
}